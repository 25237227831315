<script setup lang="ts">
import { breakpointsTailwind } from '@vueuse/core'
import { type Transition, useMotionTransitions } from '@vueuse/motion'
import type { PublicationSummary } from '~/components/card/index.vue'

const props = defineProps<{
	angle: number
	position?: [number, number]
	card: PublicationSummary
	index: number
	numCards: number
	safeZone?: DOMRect
	safeZoneEl?: HTMLElement
	isSelected?: boolean
}>()

const emit = defineEmits<{
	(e: 'select', publication: PublicationSummary, keepTimeout?: boolean): void
}>()

const el = ref<HTMLDivElement>()
const cardRef = ref()
const floatEl = ref<HTMLDivElement>()
const rotation = -30 + Math.random() * 60

const { motionProperties: containerMotion } = useMotionProperties(el)
const { push: pushContainer, stop } = useMotionTransitions()

const { motionProperties: floatingMotion } = useMotionProperties(floatEl)
const { push: pushFloat } = useMotionTransitions()

const springSettings = {
	damping: 50,
	stiffness: 220,
}

const disableAnimation = Object.keys(useRoute().query).includes(
	'disable-animation',
)

let t = 0
let positionTimeout: NodeJS.Timeout
let started = false
const { width: windowWidth, height: windowHeight } = useWindowSize()
const xRadius = 15 + Math.random() * 5
const yRadius = 15 + Math.random() * 5
const seed = Math.random() * 10000
const speedX = 4000 + Math.random() * 2000
const speedY = 4000 + Math.random() * 2000

const { isMobile } = useDevice()
const isMobileQuery = useMediaQuery(`(max-width: ${breakpointsTailwind.sm}px`)
const isReallyMobile = computed(() => isMobile || isMobileQuery.value)

// slowly move around position to create float effect
const { pause, resume } = useRafFn(({ timestamp: ts }) => {
	if (floatEl.value) {
		const t = seed + ts
		const newX = Math.cos(t / speedX) * xRadius
		const newY = Math.sin(t / speedY) * yRadius
		const r = Math.sin(t / (speedX * 1.5)) * 5
		floatingMotion.x = newX
		floatingMotion.y = newY
		floatingMotion.rotateZ = r
	}
})

const moveToSelectedPosition = () => {
	if (!props.safeZoneEl) return
	const { top, left, width, height } =
		props.safeZoneEl.getBoundingClientRect()
	const adjustedTop = top + window.scrollY

	pushContainer('x', left + width / 6, containerMotion, springSettings)
	pushContainer(
		'y',
		adjustedTop + height / 2,
		containerMotion,
		springSettings,
	)
	pushContainer('rotateZ', 0, containerMotion, springSettings)
	pushFloat('rotateZ', 0, floatingMotion, springSettings)
}

const positionAroundSafeZone = (
	distance: number,
	transition: {
		x: Transition
		y: Transition
		rotation: Transition
	} = {
		x: springSettings,
		y: springSettings,
		rotation: springSettings,
	},
) => {
	if (!props.safeZoneEl) return

	const end = getPositionAroundSafeZone(distance)

	pushContainer('x', end.x, containerMotion, transition.x)
	pushContainer('y', end.y, containerMotion, transition.y)
	pushContainer(
		'rotateZ',
		rotation < 0
			? rotation - Math.random() * 10
			: rotation + Math.random() * 10,
		containerMotion,
		transition.rotation,
	)
	pushContainer('opacity', 1, containerMotion, {
		duration: disableAnimation ? 0 : 500,
	})
}

const select = () => {
	pause()
	emit('select', props.card)
	if (!isReallyMobile.value) moveToSelectedPosition()
}

watch([windowWidth, windowHeight], () => {
	if (typeof positionTimeout !== 'undefined') clearTimeout(positionTimeout)
	if (isReallyMobile.value) return

	positionTimeout = setTimeout(() => {
		if (props.isSelected) {
			moveToSelectedPosition()
		} else {
			// TODO: distance should be bigger when we have a selected card
			// currently we only know if this card is selected, not if any other card is selected
			positionAroundSafeZone(300)
		}
	}, props.index * 10)
})

const initialPosition = ref()
watchImmediate(
	() => [props.angle, props.safeZoneEl],
	() => {
		if (!props.safeZoneEl || typeof window === 'undefined' || !props.angle)
			return
		if (typeof positionTimeout !== 'undefined')
			clearTimeout(positionTimeout)

		const delay = disableAnimation
			? 0
			: props.index * 100 + Math.random() * 100

		positionTimeout = setTimeout(() => {
			if (!props.safeZoneEl) return
			const from = getPositionAroundSafeZone(600)
			const baseTween = {
				duration: isReallyMobile.value ? 2000 : 1000,
				type: 'keyframes',
				ease: [0.12, 0.94, 0.45, 0.98],
			}
			if (disableAnimation) baseTween.duration = 0

			if (isReallyMobile.value) {
				const { x, y, rotation: r } = getMobilePosition()
				initialPosition.value = { x, y }
				pushContainer('x', x, containerMotion, {
					...baseTween,
					from:
						x > windowWidth.value / 2
							? windowWidth.value + 100
							: -100,
				})
				pushContainer('y', y, containerMotion, {
					...baseTween,
					from: y - 100,
				})
				pushContainer('rotateZ', r, containerMotion, {
					...baseTween,
					from: rotation,
				})
				pushContainer('opacity', 1, containerMotion, {
					duration: 500,
				})
			} else {
				const transitions = {
					x: { ...baseTween, from: from.x },
					y: { ...baseTween, from: from.y },
					rotation: {
						...baseTween,
						from:
							rotation < 0
								? rotation - Math.random() * 10
								: rotation + Math.random() * 10,
					},
				}
				positionAroundSafeZone(300, transitions)
			}
			if (!started) started = true
		}, delay)
	},
)

const getMobilePosition = () => {
	if (!props.safeZoneEl) return { x: 0, y: 0, rotation: 0 }
	let endX =
		props.index % 2 === 0
			? windowWidth.value / 2 - 100
			: windowWidth.value / 2 + 100

	const { height, y } = props.safeZoneEl.getBoundingClientRect()
	const adjustedTop = y + window.scrollY
	const safeY = adjustedTop + height / 2

	let endY = props.index === 0 ? safeY - 400 : safeY - 25 + props.index * 450

	let r =
		rotation < 0
			? rotation - Math.random() * 10
			: rotation + Math.random() * 10

	// last card on mobile should be selected
	if (props.index === props.numCards - 1) {
		endX = windowWidth.value / 2
		endY += 320
		r = 0
		pause()
		emit('select', props.card, true)

		floatingMotion.x = 0
		floatingMotion.y = 0
		floatingMotion.rotateZ = 0
	}

	return {
		x: endX,
		y: endY,
		rotation: r,
	}
}

const getPositionAroundSafeZone = (distance: number) => {
	if (!props.safeZoneEl) return { x: 0, y: 0 }

	const { top, left, right, bottom, width, height } =
		props.safeZoneEl.getBoundingClientRect()
	const adjustedTop = top + window.scrollY
	const adjustedBottom = bottom + window.scrollY

	const centerX = left + width / 2
	const centerY = adjustedTop + height / 2

	const vx = Math.cos(props.angle)
	const vy = Math.sin(props.angle)

	// Calculate point on edge of rectangle given an angle
	//https://stackoverflow.com/questions/3180000/calculate-a-vector-from-a-point-in-a-rectangle-to-edge-based-on-angle
	const t1 = (left - centerX) / vx
	const t2 = (right - centerX) / vx
	const t3 = (adjustedTop - centerY) / vy
	const t4 = (adjustedBottom - centerY) / vy
	t = Math.min(...[t1, t2, t3, t4].filter((t) => t >= 0))

	return {
		x: centerX + (t + distance) * vx,
		y: centerY + (t + distance) * vy,
	}
}

const moveOut = () => {
	if (cardRef.value) cardRef.value.showCardFront()
	if (!isReallyMobile.value) positionAroundSafeZone(400)
}

// In the mobile version we want cards to swap places
const moveToPosition = (
	{ x, y }: { x: number; y: number },
	newRotation?: number,
) => {
	if (cardRef.value) cardRef.value.showCardFront()
	if (!isReallyMobile.value || !x || !y) return

	const r = typeof newRotation !== 'undefined' ? newRotation : rotation

	pushContainer('x', x, containerMotion, springSettings)
	pushContainer('y', y, containerMotion, springSettings)
	pushContainer('rotateZ', r, containerMotion, springSettings)
	if (r === 0) {
		pushFloat('rotateZ', r, floatingMotion, springSettings)
		pushFloat('x', 0, floatingMotion, springSettings)
		pushFloat('y', 0, floatingMotion, springSettings)
		pause()
	} else {
		resume()
	}

	initialPosition.value = { x, y }
}

defineExpose({
	initialPosition,
	moveToPosition,
	moveOut: moveOut,
	select: select,
	slug: props.card.slug,
})
</script>

<template>
	<div class="card-container" ref="el">
		<div class="floating" ref="floatEl">
			<Card
				ref="cardRef"
				class="card -translate-x-1/2 -translate-y-1/2 pointer-events-auto"
				:publication="card"
				@click="select"
				:prevent-rotate-on-click="!isSelected"
				disable-lazy-loading
				fetch-priority="high"
			/>
		</div>
	</div>
</template>

<style scoped lang="postcss">
.card-container {
	@apply absolute opacity-0 left-0 top-0 pointer-events-none;
	transform-origin: top left;

	/* &:after {
		content: '';
		@apply absolute left-0 top-0 w-2 h-2 bg-red-500 z-10;
	} */
}

.card {
	@apply relative w-[290px] h-auto aspect-[290/400] cursor-pointer;
}
</style>
