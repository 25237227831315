<script setup lang="ts">
import { ComponentMatrixBlocksHomeIntroCardContainer } from '#components'
import { breakpointsTailwind } from '@vueuse/core'
import type { PublicationSummary } from '~/components/card/index.vue'
import type { HomeIntroBlockFragment } from '~/generated/operations'

type CardRef = InstanceType<typeof ComponentMatrixBlocksHomeIntroCardContainer>

const props = defineProps<{
	block: HomeIntroBlockFragment
}>()

const disableAnimation = Object.keys(useRoute().query).includes(
	'disable-animation',
)

const started = ref(disableAnimation ? true : false)
const publications = props.block.cards
const numCards = publications.length
const cards = ref<CardRef[]>([])
const safeZone = ref<HTMLDivElement>()
const endEl = ref<HTMLDivElement>()
const currentDescription = ref<Element>()
const angles = ref(new Array(numCards).fill(0))

let angle = Math.random() * 2 * Math.PI
new Array(numCards).fill(0).forEach((c: any, index: number) => {
	const step = (2 * Math.PI) / numCards
	const offset = -step * 0.2 + Math.random() * step * 0.4
	angle += step
	angles.value[index] = angle + offset
})

const { isMobile } = useDevice()
const isMobileQuery = useMediaQuery(`(max-width: ${breakpointsTailwind.sm}px`)
const isReallyMobile = computed(() => isMobile || isMobileQuery.value)
const { scrollY } = useScroll()

// on mobile when we scroll past the end, we want to cancel the auto selecting of a card
const isVisible = useElementVisibility(endEl)
watch(isVisible, (visible) => {
	if (
		isReallyMobile.value &&
		visible &&
		typeof autoSelectTimeout !== 'undefined'
	) {
		clearTimeout(autoSelectTimeout)
	}
})

const selected = ref<CardRef>()
const onCardSelected = (
	selectedCard: any,
	publication: PublicationSummary,
	keepTimeout: boolean,
) => {
	if (typeof autoSelectTimeout !== 'undefined' && !keepTimeout) {
		clearTimeout(autoSelectTimeout)
	}

	if (selected.value === selectedCard) return
	if (isReallyMobile.value && selected.value) {
		const goingInPosition = selectedCard.initialPosition
		const goingOutPosition = selected.value.initialPosition

		selected.value.moveToPosition(goingInPosition)
		selectedCard.moveToPosition(goingOutPosition, 0)
	} else {
		cards.value.forEach((c: any) => {
			if (c !== selectedCard) c.moveOut()
		})
	}

	if (isReallyMobile.value && selected.value) {
		setTimeout(() => {
			if (!currentDescription.value) return
			scrollToSelectedCard()
		}, 50)
	}

	selected.value = selectedCard
}

const scrollToSelectedCard = () => {
	if (!currentDescription.value) return
	window.scrollTo({
		top:
			currentDescription.value.getBoundingClientRect().top +
			window.scrollY -
			50,
		behavior: 'smooth',
	})
}

watch(scrollY, () => {
	document.body.classList.remove('hide-logo')
})

let autoSelectTimeout: NodeJS.Timeout

onMounted(() => {
	document.body.classList.add('hide-logo')

	setTimeout(() => {
		started.value = true
	}, 100)

	// automatically select card after 10 seconds
	autoSelectTimeout = setTimeout(() => {
		if (isReallyMobile.value) {
			scrollToSelectedCard()
		} else {
			const options = [...cards.value]
			options.sort(Math.random)
			options[0].select()
		}
	}, 10 * 1000)
})

onUnmounted(() => {
	document.body.classList.remove('hide-logo')
	if (typeof autoSelectTimeout !== 'undefined')
		clearTimeout(autoSelectTimeout)
})

const safeZoneBounds = useElementBounding(safeZone)
const height = computed(() => {
	if (typeof window === 'undefined') return 2768
	return Math.round(safeZoneBounds.bottom.value + window.scrollY + 2160)
})
</script>

<template>
	<div
		class="home__intro"
		:class="{
			'home__intro--start': started,
		}"
		:style="{
			height: isReallyMobile ? `${height}px` : '100vh',
		}"
	>
		<div class="cards" v-if="started">
			<ComponentMatrixBlocksHomeIntroCardContainer
				critical 
				v-for="(card, index) in publications"
				:index="index"
				:numCards="publications.length"
				class="card-container"
				ref="cards"
				:card="card.publication[0]"
				:safe-zone-el="safeZone"
				:angle="angles[index]"
				:is-selected="cards && selected === cards[index]"
				@select="
					(publication: PublicationSummary, keepTimeout) => {
						//@ts-ignore
						if (typeof document !== 'undefined') {
							//@ts-ignore
							document.body.classList.remove('hide-logo')
						}

						onCardSelected(
							cards[index],
							card.publication[0],
							keepTimeout,
						)
					}
				"
			/>
		</div>

		<div class="intro-text-container">
			<div
				class="text"
				ref="safeZone"
				:style="{
					opacity: isReallyMobile ? 1 : selected ? 0 : 1,
					// keep in dom, we use this to position cards around
				}"
			>
				<h2>{{ block.prefix }}</h2>
				<Logo class="logo" />
				<div class="description" v-html="block.description" />
			</div>
		</div>

		<div class="description-text-container">
			<TransitionGroup
				appear
				:duration="disableAnimation ? 0 : 2000"
				name="selected"
				@before-enter="(el: Element) => (currentDescription = el)"
			>
				<div
					v-for="pub in publications"
					:key="pub.publication[0].slug"
					class="text text--description"
					v-show="
						selected && selected?.slug === pub.publication[0].slug
					"
				>
					<PillLabel class="pill" color="black">{{
						pub.prefix
					}}</PillLabel>
					<h2>{{ pub.heading }}</h2>
					<div class="description" v-html="pub.description" />
				</div>
			</TransitionGroup>
		</div>
		<div class="the-end" ref="endEl"></div>
	</div>
</template>

<style scoped lang="postcss">
.the-end {
	@apply w-full h-px absolute bottom-0;
}
.home__intro {
	@apply flex justify-center items-center w-full md:h-screen relative overflow-hidden;

	.intro-text-container,
	.description-text-container {
		@apply absolute top-0 left-0 flex justify-center items-center w-screen h-[100svh] md:h-screen pointer-events-none;
	}

	.description-text-container {
		@apply h-[896px] md:h-screen;
	}

	.description-text-container {
		@apply bottom-0 top-auto;
	}

	.text {
		@apply w-full px-7 sm:px-0 sm:w-[50vw] max-w-[600px] text-center pointer-events-none xl:p-12 2xl:p-28 box-content max-sm:pt-16;

		h2 {
			@apply text-[18px] sm:text-[30px] mb-5 translate-y-12 opacity-0 delay-100 transition-all duration-500 leading-none;
		}

		.description {
			@apply text-[16px] sm:text-[18px] mt-6 sm:mt-8 translate-y-16  opacity-0 delay-200 transition-all duration-500;
		}

		.logo {
			@apply w-full translate-y-16 transition-transform duration-500;
		}

		&--description {
			@apply absolute w-[300px] px-0 text-center bottom-[520px] md:bottom-auto md:left-1/2 md:w-[400px] md:text-left;

			h2 {
				@apply text-[38px] md:text-[60px] font-semibold mt-3 mb-4 md:my-5 tracking-tight;
			}

			.description {
				@apply mt-0 text-[16px];
			}
		}
	}

	&--start {
		.text {
			.logo,
			h2,
			.description {
				@apply translate-y-0 opacity-100;
			}
		}
	}
}

.cards {
	@apply absolute w-full h-full overflow-hidden;
}

.selected-enter-active,
.selected-leave-active {
	&.text.text--description {
		.pill,
		h2,
		.description {
			transition-property: opacity, transform;
			transition-duration: 300ms;
			transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		}
	}
}

.selected-enter-from,
.selected-leave-to {
	&.text.text--description {
		.pill,
		h2,
		.description {
			opacity: 0;
			transform: translateY(10px);
		}
	}
}

.selected-leave-active {
	&.text.text--description {
		.pill {
			transition-delay: 0ms;
		}

		h2 {
			transition-delay: 150ms;
		}
		.description {
			transition-delay: 300ms;
		}
	}
}

.selected-enter-active {
	&.text.text--description {
		.pill {
			transition-delay: 700ms;
		}

		h2 {
			transition-delay: 900ms;
		}
		.description {
			transition-delay: 1100ms;
		}
	}
}
</style>
